import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Coordinate, FreightType } from "../../types/common/common";
import { BidPortMap } from "../../types/forwarding/bid";
import { isEmptyObjectOrArray } from "../../utils/common/etc";
import { findNearestIndex } from "../../utils/common/googleMap";

import { SvgTypeIconInfo } from "../../components/googleMap/GoogleMapWithCluster";

import { GET_BID_TRACKING_SHIP_RES } from "../../api-interfaces/shipda-api/bid";
import { APP_CURRENT_LANGUAGE } from "../../constants";

export default function useShipmentMapMarkerInfoList({
  trackingShipResult,
  freightType,
}: {
  trackingShipResult: GET_BID_TRACKING_SHIP_RES | undefined;
  freightType: FreightType | undefined;
}) {
  const { t } = useTranslation(["bid"]);

  function getBidMapPortCoordinate(port?: BidPortMap): Coordinate | undefined {
    if (!port || !port.lat || !port.lng) {
      return;
    }

    return {
      lat: port.lat,
      lng: port.lng,
      name:
        APP_CURRENT_LANGUAGE === "ko" ? port.name || port.nameEN : port.nameEN,
    };
  }

  /** 현재 운송 진행중 여부 */
  const hasMoving =
    trackingShipResult &&
    trackingShipResult.pin &&
    Boolean(trackingShipResult.pin.lat) &&
    Boolean(trackingShipResult.pin.lng);

  /** 맵의 센터 포지션 */
  const centerPosition = useMemo(() => {
    if (hasMoving) {
      return {
        lat: trackingShipResult.pin.lat,
        lng: trackingShipResult.pin.lng,
      };
    }

    return { lat: 35, lng: 125 };
  }, [hasMoving, trackingShipResult]);

  const polylineData = useMemo(() => {
    if (!trackingShipResult) return;

    if (isEmptyObjectOrArray(trackingShipResult.routes)) return;

    return trackingShipResult.routes.flatMap((routeArray) =>
      routeArray.map((port) => ({ lat: port.lat, lng: port.lng }))
    );
  }, [trackingShipResult]);

  const splitIndex = useMemo(() => {
    if (!trackingShipResult) return;

    if (!polylineData) return;

    return findNearestIndex({
      pinLat: trackingShipResult?.pin.lat,
      pinLng: trackingShipResult?.pin.lng,
      routes: polylineData,
    });
  }, [polylineData, trackingShipResult]);

  /** 출발지 위도, 경도를 구하는 함수 */
  const getDeparture = useCallback((): Coordinate | undefined => {
    const targetPort = trackingShipResult?.startPort;

    if (!targetPort) return undefined;

    return getBidMapPortCoordinate(targetPort);
  }, [trackingShipResult?.startPort]);

  /** 도착지 위도, 경도를 구하는 함수 */
  const getDestination = useCallback((): Coordinate | undefined => {
    const targetPort = trackingShipResult?.endPort;

    if (!targetPort) return undefined;

    return getBidMapPortCoordinate(targetPort);
  }, [trackingShipResult?.endPort]);

  /** 경유지를 구하는 함수 */
  const getStopovers = useCallback((): Coordinate[] | undefined => {
    if (!trackingShipResult) return;

    if (isEmptyObjectOrArray(trackingShipResult)) return;

    if (!trackingShipResult.pin) return;

    // 항적(폴리라인)이 있다면 마커의 위치를 실선 폴리라인의 마지막 좌표로 설정
    if (polylineData && splitIndex) {
      return [polylineData[splitIndex]];
    }

    return [
      {
        lat: trackingShipResult.pin.lat,
        lng: trackingShipResult.pin.lng,
      },
    ];
  }, [polylineData, splitIndex, trackingShipResult]);

  /** 현재 이동중인 화물 위도, 경도 */
  const shipPosition = useMemo((): Coordinate | undefined => {
    const stopovers = getStopovers();
    if (!stopovers?.length) {
      return getDeparture();
    }

    return stopovers[0];
  }, [getDeparture, getStopovers]);

  const markerInfoList = useMemo(() => {
    if (!trackingShipResult) return;

    const parser = new DOMParser();

    const departure = getDeparture();
    const destination = getDestination();

    const infoIconImgSrc =
      freightType === "AIR"
        ? "/assets/images/mypage/bid/marker-info-icon-plane.svg"
        : "/assets/images/mypage/bid/marker-info-icon-ship.svg";

    const departureInfo: Node = (() => {
      const infoContainer = document.createElement("div");
      infoContainer.className = "info-container";

      const div = document.createElement("div");
      div.className = "departure";

      const title = document.createElement("div");
      title.className = "title";

      const titleImg = document.createElement("img");
      titleImg.src = infoIconImgSrc;

      const titleText = document.createElement("div");
      titleText.textContent = t("bid:운송관리_공통_TRACKING_MAP_출발지");

      title.appendChild(titleImg);
      title.appendChild(titleText);
      div.appendChild(title);

      const port = document.createElement("div");
      port.className = "port";
      port.textContent = departure?.name || "";

      div.appendChild(port);

      infoContainer.appendChild(div);

      return infoContainer;
    })();

    const destinationInfo: Node = (() => {
      const infoContainer = document.createElement("div");
      infoContainer.className = "info-container";

      const div = document.createElement("div");
      div.className = "destination";

      const title = document.createElement("div");
      title.className = "title";

      const titleImg = document.createElement("img");
      titleImg.src = infoIconImgSrc;

      const titleText = document.createElement("div");
      titleText.textContent = t("bid:운송관리_공통_TRACKING_MAP_도착지");

      title.appendChild(titleImg);
      title.appendChild(titleText);
      div.appendChild(title);

      const port = document.createElement("div");
      port.className = "port";
      port.textContent = destination?.name || "";

      div.appendChild(port);

      infoContainer.appendChild(div);

      return infoContainer;
    })();

    const shipSvg = `<svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Polygon 4" d="M6 0L12 18L6 15L0 18L6 0Z" fill="#D65481"/>
    </svg>
    `;

    const portSvg = `<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle id="Ellipse 10" cx="4" cy="4" r="3.5" fill="#BDBDBD" stroke="#616161"/>
</svg>
`;

    const parsedShipSvg = parser.parseFromString(
      shipSvg,
      "image/svg+xml"
    ).documentElement;

    const parsedDepartureSvg = parser.parseFromString(
      portSvg,
      "image/svg+xml"
    ).documentElement;

    const parsedDestinationPortSvg = parser.parseFromString(
      portSvg,
      "image/svg+xml"
    ).documentElement;

    parsedShipSvg.style.transform = `translate(5%, 55%) rotate(${
      // heading 값이 null일 때가 있음
      trackingShipResult.pin.heading || 0
    }deg)`;
    parsedShipSvg.style.transformOrigin = "center";

    parsedDepartureSvg.style.position = "absolute";
    parsedDepartureSvg.style.transform = "translate(-50%, -50%)";

    parsedDestinationPortSvg.style.position = "absolute";
    parsedDestinationPortSvg.style.transform = "translate(-50%, -50%)";

    return [
      ...(departure
        ? [
            {
              ...departure,
              iconInfo: {
                type: "svg",
                svg: parsedDepartureSvg,
              } as SvgTypeIconInfo,
              title: "departureIcon",
            },
            {
              ...departure,
              markerInfo: departureInfo,
              title: "departureInfo",
            },
          ]
        : []),

      ...(shipPosition?.lat && shipPosition?.lng
        ? [
            {
              ...shipPosition,
              iconInfo: {
                type: "svg",
                svg: parsedShipSvg,
              } as SvgTypeIconInfo,
              title: "shipPosition",
            },
          ]
        : []),

      ...(destination
        ? [
            {
              ...destination,
              iconInfo: {
                type: "svg",
                svg: parsedDestinationPortSvg,
              } as SvgTypeIconInfo,
              title: "destinationIcon",
            },
            {
              ...destination,
              markerInfo: destinationInfo,
              title: "destinationInfo",
            },
          ]
        : []),
    ];
  }, [
    freightType,
    getDeparture,
    getDestination,
    shipPosition,
    t,
    trackingShipResult,
  ]);

  return {
    centerPosition,
    markerInfoList,
  };
}
